import { AspectRatio, Flex, Heading } from "@chakra-ui/react";
import Image from "next/image";

// Context
import { useAuth } from "ui";

import { getUserRole } from "utils";

const ProfileHeader = () => {
  const { auth } = useAuth();
  return (
    <Flex direction="column" gap={4}>
      <Heading size="lg" as="h1">
        {`${auth.name.firstName}, welcome to your dashboard!`}
      </Heading>
      <div>
        {/* TODO: */}
        {/* <AspectRatio
          ratio={1}
          w={150}
          rounded="full"
          position="relative"
          overflow="hidden"
        >
          <Image
            src="https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images/mat_stretch.jpg"
            alt="Profile Picture"
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            fill
          />
        </AspectRatio> */}
        {/* <Heading size="md" as="h2">
          {getUserRole(auth)}
        </Heading> */}
      </div>
    </Flex>
  );
};

export default ProfileHeader;
