import ProfileHeader from "views/profile/ProfileHeader";
import { UpcomingBookings } from "ui";

const DashboardHome = ({}) => (
  <div>
    <ProfileHeader />
    <UpcomingBookings />
  </div>
);

export default DashboardHome;
