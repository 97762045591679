import * as ROLES from "consts";

interface UserRole {
  role: { name: string };
}

export const getUserRole = (user: UserRole) => {
  switch (user.role.name) {
    case ROLES.GOD:
      return "GOD";
    case ROLES.SYSTEM_ADMIN:
      return "System Admin";
    case ROLES.ADMIN:
      return "Admin";
    case ROLES.CLIENT:
      return "Client";
    case ROLES.TRAINER:
      return "Personal Trainer";
    default:
      return "";
  }
};
